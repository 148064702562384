<template>
  <!-- 主营业务 -->
  <div class="zong">
    <!-- <p>主要业务产品</p> -->

    <div style="display:flex;justify-content: space-around;width: 100%;">
      <div>
        <!-- <a href="http://192.168.1.202:7007/" target="_blank"> -->
        <img src="@/assets/img/productProject/zhps.png" alt="智慧水务系统">
        <p>智慧水务系统</p>
        <!-- </a> -->
      </div>
      <div>
        <!-- <a href="http://192.168.1.202:7004/" target="_blank"> -->
        <img src="@/assets/img/productProject/zhps.png" alt="城市基础设施生命线系统">
        <p>城市基础设施生命线系统</p>
        <!-- </a> -->
      </div>
      <div>
        <!-- <a href="http://192.168.1.202:7003/" target="_blank"> -->
        <img src="@/assets/img/productProject/zhps.png" alt="城镇两污巡查系统">
        <p>城镇两污巡查系统</p>
        <!-- </a> -->
      </div>
      <div>
        <!-- <a href="http://waterweb.swyth.cn/#/water/supply/overview?token=xl001" target="_blank"> -->
        <img src="@/assets/img/productProject/zhps.png" alt="供水综合调度管理系统">
        <p>供水综合调度管理系统</p>
        <!-- </a> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "mainBusiness"
}
</script>

<style lang="less" scoped>
.zong {
  width: 100%;
  height: 100vh;
  background-color: antiquewhite;
  background-image: url('@/assets/img/mainBusiness.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
}
</style>