<template>
  <div>
    <!-- 固定的导航栏 -->
    <div class="header">
      <img alt="Vue logo" src="@/assets/logo.png" style="width: 10rem" />
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="#393636"
        active-text-color="#1C66FF"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">主要业务产品</el-menu-item>
        <el-menu-item index="3">联系我们</el-menu-item>
      </el-menu>
    </div>

    <div>
      <component :is="currentComponent"></component>
    </div>
  </div>
</template>

<script>
import homePage from '@/components/homePage.vue'
import mainBusiness from '@/components/mainBusiness.vue'
import contactUs from '@/components/contactUs.vue'

export default {
  components: {
    homePage,
    mainBusiness,
    contactUs,
  },
  data() {
    return {
      activeIndex: '1',
    }
  },
  computed: {
    currentComponent() {
      switch (this.activeIndex) {
        case '1':
          return 'homePage'
        case '2':
          return 'mainBusiness'
        case '3':
          return 'contactUs'
        default:
          return 'homePage' // 默认首页
      }
    },
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  /* 固定定位 */
  top: 0;
  /* 距离顶部0 */
  left: 0;
  /* 距离左侧0 */
  right: 0;
  /* 距离右侧0 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20rem;
  background-color: #f8fbffcc;
  margin-bottom: 0;
  /* 去掉底部边距 */
  box-shadow: 0 3px 5px 5px rgba(28, 102, 255, 0.5) !important;
  z-index: 1000;
  /* 确保在其他元素之上 */
}

/* 为了让内容不会被固定的导航栏遮挡，添加顶部内边距 */
body {
  padding-top: 70px;
  /* 根据导航栏高度调整 */
}

.el-menu {
  background: transparent;
}

/* 鼠标移入 */
.el-menu-item:hover {
  color: #a0cfff;
  background-color: #eaeeffaa !important;
}

/* 鼠标选中 */
.el-menu-item.is-active {
  color: #6681fa;
  background-color: transparent !important;
}
</style>
