<template>
  <!-- 首页 -->
  <div>
    <div class="background-container">
      <div style="width: 45%; padding: 5rem 0 0 10rem">
        <h1 style="color: #1c66ff; font-size: 3.5rem; font-weight: 800">云南云派思维科技有限公司</h1>
        <p style="font-size: 24px; color: black">
          云南云派思维科技有限公司致力于住建、环境保护、自然资源、水务水利等领域，信息化建设工作中城市基础设施生命线安全工程系统在智慧排水、城乡污水处理信息化以及业务方向领域积累了丰富的经验。
        </p>
      </div>
    </div>

    <div style="font-size: 1.5rem; font-weight: 600; border-left: 1rem solid #1c66ff; margin: 1rem 0 0 10rem; padding-left: 1rem">公司简介</div>
    <div style="width: 80%; margin: auto; display: flex; justify-content: space-between">
      <div style="flex: 1">
        <p style="font-size: 20px; color: black">
          公司设有昆明总部和武汉研发部，其中武汉研发部负责公司技术和产品的研发工作，昆明总部负责项目实施研发、售前技术支持等工作。研发团队由资深信息系统专家和长期专注于智慧水务研发的专业人员组成，并与排水、环保以及城市污水处理领域的行业专家和高等院校建立了紧密的合作关系，为信息系统产品的研发和项目实施提供专业技术支持。
        </p>
        <img src="@/assets/img/Job composition.png" style="width: 90%; margin-top: 5rem" alt="" />
      </div>
      <img src="@/assets/img/shangban.png" style="flex: 1; width: 100%; height: 40rem" alt="" />
    </div>
    <div style="text-align: center;line-height: 30px;">
      copyright©2024 云南云派思维科技有限公司 All Right Reserved <br />
      <span>ICP备案/许可证号：</span><a href="https://beian.miit.gov.cn/">滇ICP备2024047968号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homePage',
}
</script>

<style scoped>
.background-container {
  width: 100%;
  height: 30rem;
  background-color: antiquewhite;
  background-image: url('@/assets/img/homePage.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
